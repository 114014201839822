import {
  Collapse,
  FormControl,
  FormControlLabel,
  MenuItem,
  RadioGroup,
  Select,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { TFunction } from 'i18next'
import _ from 'lodash'
import { ChangeEvent, useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { TAX_RATE_PERCENT_PRECISION, decimalToPercent } from 'siteline-common-all'
import { SitelineText, makeStylesFast, useToggle } from 'siteline-common-web'
import { SitelineRadio } from '../../../common/components/SitelineRadio'
import { TaxCalculationType, TaxGroupProperties } from '../../../common/graphql/apollo-operations'
import { AddOrEditTaxGroupDialog } from '../invoice/taxes/AddOrEditTaxGroupDialog'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    '& .calculationTypeLabel': {
      display: 'flex',
    },
    '& .selectContainer': {
      // Align vertically with radio button label
      margin: theme.spacing(0, 0, 1, 4),
      '& .selectDescription': {
        display: 'block',
        margin: theme.spacing(-0.5, 0, 1, 0),
      },
    },
    '& .bold': {
      fontWeight: 600,
    },
  },
  menuItem: {
    ...theme.typography.body2,
  },
}))

const i18nBase = 'projects.subcontractors.settings.taxes'

const taxCalculationTypes = [
  TaxCalculationType.NONE,
  TaxCalculationType.SINGLE_TAX_GROUP,
  TaxCalculationType.MULTIPLE_TAX_GROUPS,
]
const ADD_TAX_GROUP_MENU_ITEM_ID = 'addTaxGroup'

export function findAndFormatTaxGroupLabel(
  taxGroupId: string | null,
  taxGroups: TaxGroupProperties[],
  t: TFunction
) {
  const taxGroup = taxGroups.find((group) => group.id === taxGroupId)
  return taxGroup
    ? `${taxGroup.name} (${decimalToPercent(taxGroup.taxPercent, TAX_RATE_PERCENT_PRECISION)}%)`
    : t('common.none')
}

export interface TaxCalculationOptionsProps {
  isEditing: boolean
  canEdit: boolean
  selectedCalculationType: TaxCalculationType
  onSelectedCalculationTypeChange: (newCalculationType: TaxCalculationType) => void
  defaultTaxGroup: TaxGroupProperties | null
  onDefaultTaxGroupChange: (newDefaultTaxGroup: TaxGroupProperties | null) => void
  taxGroups: TaxGroupProperties[]
  location: 'createProject' | 'settings'
}

/** Shows radio buttons for selecting a tax calculation type */
export function TaxCalculationOptions({
  isEditing,
  canEdit,
  selectedCalculationType,
  onSelectedCalculationTypeChange,
  defaultTaxGroup,
  onDefaultTaxGroupChange,
  taxGroups,
  location,
}: TaxCalculationOptionsProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [isDialogOpen, handleOpenDialog, handleCloseDialog] = useToggle()

  // If the user is switching to SINGLE_TAX_GROUP but first needs to add a tax group, we keep track
  // of the previous calculation type so we can switch back if they fail to add a tax group
  const [fallbackCalculationType, setFallbackCalculationType] = useState<TaxCalculationType | null>(
    null
  )

  const handleUpdateCalculationType = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const newCalculationType = evt.target.value as TaxCalculationType
      switch (newCalculationType) {
        case TaxCalculationType.NONE:
          // There should never be a default tax group if there is no tax calculation. Wait a moment
          // to make the update so the select component has finished collapsing.
          setTimeout(() => onDefaultTaxGroupChange(null), 200)
          break
        case TaxCalculationType.SINGLE_TAX_GROUP: {
          if (taxGroups.length === 0) {
            // If there are no tax groups, open the dialog to create one. If the user cancels,
            // we'll switch the setting back.
            setFallbackCalculationType(selectedCalculationType)
            handleOpenDialog()
          } else if (!defaultTaxGroup) {
            // If there's already a default tax group (e.g. we are switching from multiple tax
            // groups to single tax group), we can preserve the current default. Otherwise, we need
            // a default, and we just use the first known tax group.
            onDefaultTaxGroupChange(_.first(taxGroups) ?? null)
          }
          break
        }
        case TaxCalculationType.MULTIPLE_TAX_GROUPS:
          // With multiple tax groups it's fine to have a default tax group or a default of None,
          // so we just preserve whatever is already set
          break
      }
      onSelectedCalculationTypeChange(newCalculationType)
    },
    [
      defaultTaxGroup,
      handleOpenDialog,
      onDefaultTaxGroupChange,
      onSelectedCalculationTypeChange,
      selectedCalculationType,
      taxGroups,
    ]
  )

  const handleCloseTaxesDialog = useCallback(() => {
    if (fallbackCalculationType) {
      onSelectedCalculationTypeChange(fallbackCalculationType)
      setFallbackCalculationType(null)
    }
    handleCloseDialog()
  }, [fallbackCalculationType, handleCloseDialog, onSelectedCalculationTypeChange])

  const handleAddTaxGroup = useCallback(
    async (taxGroup: TaxGroupProperties) => {
      onDefaultTaxGroupChange(taxGroup)
      setFallbackCalculationType(null)
      handleCloseDialog()
    },
    [handleCloseDialog, onDefaultTaxGroupChange]
  )

  return (
    <div className={classes.root}>
      {isEditing && (
        <RadioGroup
          name="tax-setting"
          value={selectedCalculationType}
          onChange={handleUpdateCalculationType}
        >
          {taxCalculationTypes.map((taxCalculationType) => {
            const isSelected = taxCalculationType === selectedCalculationType
            return (
              <div key={taxCalculationType}>
                <FormControlLabel
                  value={taxCalculationType}
                  control={<SitelineRadio color="primary" size="small" />}
                  label={t(`${i18nBase}.radio_buttons.${taxCalculationType}`)}
                />
                <Collapse
                  in={
                    taxCalculationType !== TaxCalculationType.NONE &&
                    isSelected &&
                    !fallbackCalculationType
                  }
                >
                  <div className="selectContainer">
                    <SitelineText variant="secondary" color="grey50" className="selectDescription">
                      {taxCalculationType === TaxCalculationType.MULTIPLE_TAX_GROUPS
                        ? t(`${i18nBase}.select_default_tax_group`)
                        : t(`${i18nBase}.select_tax_group`)}
                    </SitelineText>
                    <FormControl variant="outlined" className="selectTaxGroup">
                      <Select
                        value={defaultTaxGroup?.id ?? ''}
                        onChange={(evt) => {
                          if (evt.target.value === ADD_TAX_GROUP_MENU_ITEM_ID) {
                            return
                          }
                          const taxGroup = taxGroups.find((group) => group.id === evt.target.value)
                          onDefaultTaxGroupChange(taxGroup ?? null)
                        }}
                        displayEmpty
                        renderValue={(value) => (
                          <SitelineText variant="body1" color={value ? 'grey90' : 'grey50'}>
                            {findAndFormatTaxGroupLabel(value, taxGroups, t)}
                          </SitelineText>
                        )}
                        disabled={!canEdit || !isEditing}
                      >
                        {selectedCalculationType === TaxCalculationType.MULTIPLE_TAX_GROUPS && (
                          <MenuItem value="" className={classes.menuItem}>
                            {t('common.none')}
                          </MenuItem>
                        )}
                        {taxGroups.map((taxGroup) => (
                          <MenuItem
                            key={taxGroup.id}
                            value={taxGroup.id}
                            className={classes.menuItem}
                          >
                            {findAndFormatTaxGroupLabel(taxGroup.id, taxGroups, t)}
                          </MenuItem>
                        ))}
                        <MenuItem
                          value={ADD_TAX_GROUP_MENU_ITEM_ID}
                          className={classes.menuItem}
                          onClick={handleOpenDialog}
                        >
                          {t(`${i18nBase}.new_tax_group`)}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </Collapse>
              </div>
            )
          })}
        </RadioGroup>
      )}
      {!isEditing && (
        <SitelineText variant="body1">
          <Trans
            i18nKey={`${i18nBase}.current_setting.${selectedCalculationType}`}
            values={{
              name: defaultTaxGroup?.name,
              percent: decimalToPercent(
                defaultTaxGroup?.taxPercent ?? 0,
                TAX_RATE_PERCENT_PRECISION
              ),
            }}
            components={{ bold: <span className="bold" /> }}
          />
        </SitelineText>
      )}
      <AddOrEditTaxGroupDialog
        open={isDialogOpen}
        onClose={handleCloseTaxesDialog}
        // We are never editing a tax group from this dialog, since it's only for adding new
        // tax groups, so we always pass null
        taxGroup={null}
        onAddTaxGroup={handleAddTaxGroup}
        location={location}
      />
    </div>
  )
}
