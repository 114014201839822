import { gql } from '@apollo/client'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { SitelineText, useSitelineSnackbar } from 'siteline-common-web'
import { Mailto } from '../../../common/components/Mailto'
import { RouterLink } from '../../../common/components/RouterLink'
import { SitelineDialog } from '../../../common/components/SitelineDialog'
import { config } from '../../../common/config/constants'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import { useUserContext } from '../../../common/contexts/UserContext'
import {
  ChangeOrderRequestActivityDocument,
  ChangeOrderRequestProperties,
  Permission,
  useCompanyUsersQuery,
  useGetContractForChangeOrderRequestReviewQuery,
  useRequestChangeOrderReviewMutation,
} from '../../../common/graphql/apollo-operations'
import * as fragments from '../../../common/graphql/Fragments'
import { filterOutSitelineEmails } from '../../../common/util/User'
import { Contact } from '../submit-dialog/NewContactForm'
import { SendEmailDialog } from '../submit-dialog/SendEmailDialog'
import { EmailContact } from '../submit-dialog/SendEmailDialogForm'
gql`
  query getContractForChangeOrderRequestReview($input: GetContractByProjectIdInput!) {
    contractByProjectId(input: $input) {
      id
      rateTableTaxCalculationType
      rateTableDefaultTaxGroup {
        ...TaxGroupProperties
      }
      company {
        id
        name
      }
      defaultChangeOrderRequestReviewContacts {
        id
        email
        firstName
        lastName
        policiesAcceptedAt
      }
      users {
        id
        user {
          id
          firstName
          lastName
          email
          policiesAcceptedAt
        }
      }
      project {
        id
        name
      }
    }
  }
  ${fragments.taxGroup}
`

interface ChangeOrderRequestReviewDialogProps {
  open: boolean
  onClose: () => void
  changeOrderRequest?: ChangeOrderRequestProperties
}

const i18nBase = 'projects.subcontractors.change_order_requests.request_review'

/** Dialog for notifying your team that you've made updates to the invoice */
export function ChangeOrderRequestReviewDialog({
  open,
  onClose,
  changeOrderRequest,
}: ChangeOrderRequestReviewDialogProps) {
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()
  const currentUser = useUserContext()
  const { id: projectId } = useProjectContext()
  const { companyId, permissions } = useCompanyContext()
  const { data } = useGetContractForChangeOrderRequestReviewQuery({
    variables: {
      input: { projectId, companyId },
    },
  })
  const { data: companyUsersData } = useCompanyUsersQuery({
    variables: { id: companyId },
  })
  const companyUsers = useMemo(
    () => filterOutSitelineEmails(currentUser, [...(companyUsersData?.companyUsers ?? [])]),
    [currentUser, companyUsersData]
  )
  const contract = data?.contractByProjectId
  const initialReviewContacts = useMemo(() => {
    if (contract) {
      const otherInvoiceContacts = _.chain(contract.defaultChangeOrderRequestReviewContacts)
        .filter((contact) => companyUsers.some((companyUser) => companyUser.user.id === contact.id))
        .filter((contact) => contact.id !== currentUser.id)
        .value()
      return otherInvoiceContacts.map((contact) => ({
        id: contact.id,
        fullName: `${contact.firstName} ${contact.lastName}`,
        email: contact.email,
      }))
    }
    return []
  }, [contract, currentUser, companyUsers])
  const [reviewContacts, setReviewContacts] = useState<EmailContact[]>(initialReviewContacts)
  const [requestChangeOrderReview, { loading: sending }] = useRequestChangeOrderReviewMutation({
    update(cache) {
      if (contract) {
        cache.modify({
          id: cache.identify(contract),
          fields: {
            defaultChangeOrderRequestReviewContacts() {
              return reviewContacts
            },
          },
        })
      }
    },
    ...(changeOrderRequest && {
      refetchQueries: [
        {
          query: ChangeOrderRequestActivityDocument,
          variables: {
            changeOrderRequestId: changeOrderRequest.id,
          },
        },
      ],
    }),
  })

  let teamMembers: Contact[] = []
  if (contract) {
    teamMembers = _.chain(contract.users)
      .map((contractUser) => contractUser.user)
      .filter((user) => companyUsers.some((companyUser) => companyUser.user.id === user.id))
      .filter((user) => user.id !== currentUser.id && user.policiesAcceptedAt !== null)
      .map((user) => ({
        id: user.id,
        fullName: `${user.firstName} ${user.lastName}`,
        email: user.email,
        companyName: contract.company.name,
        phoneNumber: null,
        jobTitle: null,
      }))
      .value()
  }

  // Reset the contacts to the default list whenever the contacts change
  useEffect(() => {
    setReviewContacts(initialReviewContacts)
  }, [initialReviewContacts])

  const projectName = contract?.project.name ?? ''
  const defaultSubject = changeOrderRequest
    ? t(`${i18nBase}.default_subject`, {
        number: changeOrderRequest.internalNumber ?? '',
        projectName,
      })
    : t(`${i18nBase}.default_subject_log`, { projectName })

  const handleSubmit = async ({ subject, message }: { subject: string; message?: string }) => {
    if (!contract) {
      return
    }

    await requestChangeOrderReview({
      variables: {
        input: {
          contractId: contract.id,
          ...(changeOrderRequest && { changeOrderRequestId: changeOrderRequest.id }),
          recipientUserIds: reviewContacts.map((contact) => contact.id),
          subject,
          // Intentionally use || to avoid passing an empty string
          message: message || undefined,
        },
      },
    })

    onClose()
    snackbar.showSuccess(t(`${i18nBase}.snackbar`, { count: reviewContacts.length }))
  }

  if (teamMembers.length === 0) {
    const i18nKey = permissions.includes(Permission.EDIT_COMPANY_USERS)
      ? (`${i18nBase}.manage_users` as const)
      : (`${i18nBase}.contact_support` as const)
    return (
      <SitelineDialog
        title={t(`${i18nBase}.no_teammates`)}
        maxWidth="sm"
        open={open}
        onClose={onClose}
      >
        <SitelineText variant="body1">
          <Trans
            i18nKey={i18nKey}
            values={{ email: config.support.email }}
            components={{
              emailLink: (
                <Mailto
                  email={config.support.email}
                  subject={t(`${i18nBase}.add_teammates_subject`)}
                  body={t(`${i18nBase}.add_teammates_body`, {
                    projectName: contract?.project.name ?? '',
                  })}
                />
              ),
              settingsLink: <RouterLink to="/settings/company/users" underline="hover" />,
            }}
          />
        </SitelineText>
      </SitelineDialog>
    )
  }

  let title = t(`${i18nBase}.modal_title_log`)
  if (changeOrderRequest) {
    title = changeOrderRequest.internalNumber
      ? t(`${i18nBase}.modal_title_cor`, { number: changeOrderRequest.internalNumber })
      : t(`${i18nBase}.modal_title`)
  }

  return (
    <SendEmailDialog
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={title}
      defaultSubject={defaultSubject}
      submitLabel={t(`${i18nBase}.send`)}
      contacts={reviewContacts}
      onContactsChange={setReviewContacts}
      onRemoveContact={(contactId) => {
        setReviewContacts(reviewContacts.filter((contact) => contact.id !== contactId))
      }}
      availableContacts={teamMembers}
      subscript=""
      submitting={sending}
      companyId={companyId}
    />
  )
}
