import AddIcon from '@mui/icons-material/Add'
import { Button, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { styled } from '@mui/system'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { colors, useToggle } from 'siteline-common-web'
import { TaxGroupProperties } from '../../../common/graphql/apollo-operations'
import { formatTaxGroupLabel } from '../../../common/util/TaxGroup'
import { AddOrEditTaxGroupDialog } from '../invoice/taxes/AddOrEditTaxGroupDialog'

const StyledRateTableTaxGroupInput = styled('div')(() => ({
  '& .menuItem': {
    '&.MuiMenuItem-root': {
      maxWidth: 'initial',
    },
  },
  '& .clearIcon': {
    cursor: 'pointer',
    color: colors.grey50,
  },
}))

const StyledMenuItem = styled(MenuItem)(() => ({
  '&.MuiButtonBase-root': {
    // Override default max width set in `Main.ts`
    maxWidth: 'unset',
  },
}))

const i18nBase = 'projects.onboarding.checklist'
const NEW_TAX_GROUP_ID = 'new-tax-group'
const DEFAULT_TAX_GROUP_ID = 'default-tax-group'

interface RateTableTaxGroupInputProps {
  taxGroups: TaxGroupProperties[]
  selectedTaxGroupId: string | null
  onSelectTaxGroup: (taxGroupId: string | null) => void
  isOverride?: boolean
}

/** Dropdown component that handles adding a rate table tax group to a project */
export function RateTableTaxGroupInput({
  taxGroups,
  selectedTaxGroupId,
  onSelectTaxGroup,
  isOverride,
}: RateTableTaxGroupInputProps) {
  const { t } = useTranslation()

  const [isAddTaxGroupDialogOpen, handleOpenTaxGroupDialog, handleCloseTaxGroupDialog] = useToggle()

  const handleTaxGroupChange = useCallback(
    (event: SelectChangeEvent<string | null>) => {
      const value = event.target.value
      if (value === null || value === NEW_TAX_GROUP_ID) {
        handleOpenTaxGroupDialog()
        return
      }
      if (value === DEFAULT_TAX_GROUP_ID) {
        onSelectTaxGroup(null)
        return
      }
      onSelectTaxGroup(value)
    },
    [handleOpenTaxGroupDialog, onSelectTaxGroup]
  )

  const handleAddTaxGroupAfterCreate = useCallback(
    (taxGroup: TaxGroupProperties) => {
      onSelectTaxGroup(taxGroup.id)
      handleCloseTaxGroupDialog()
    },
    [onSelectTaxGroup, handleCloseTaxGroupDialog]
  )

  const shouldDisplayDropdown = taxGroups.length > 0

  return (
    <StyledRateTableTaxGroupInput>
      {!shouldDisplayDropdown && (
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleOpenTaxGroupDialog}
          startIcon={<AddIcon />}
        >
          {t(`${i18nBase}.add_tax_group`)}
        </Button>
      )}
      {shouldDisplayDropdown && (
        <Select
          variant="outlined"
          fullWidth
          value={selectedTaxGroupId ?? ''}
          onChange={handleTaxGroupChange}
        >
          <StyledMenuItem value={NEW_TAX_GROUP_ID}>{t(`${i18nBase}.add_tax_group`)}</StyledMenuItem>
          {isOverride && (
            <StyledMenuItem value={DEFAULT_TAX_GROUP_ID}>
              {t(`${i18nBase}.use_default_tax_group`)}
            </StyledMenuItem>
          )}
          {taxGroups.map((taxGroup) => (
            <StyledMenuItem key={taxGroup.id} value={taxGroup.id}>
              {formatTaxGroupLabel(taxGroup, t)}
            </StyledMenuItem>
          ))}
        </Select>
      )}
      <AddOrEditTaxGroupDialog
        open={isAddTaxGroupDialogOpen}
        onClose={handleCloseTaxGroupDialog}
        taxGroup={null}
        onAddTaxGroup={handleAddTaxGroupAfterCreate}
        location="rateTableTaxSettings"
      />
    </StyledRateTableTaxGroupInput>
  )
}
