import { Theme } from '@mui/material/styles'
import { useSearch } from '@tanstack/react-router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BillingType } from 'siteline-common-all'
import { makeStylesFast } from 'siteline-common-web'
import { ContainerWithSideTabs } from '../../../common/components/ContainerWithSideTabs'
import { useWaypoint } from '../../../common/util/ReactWaypoint'
import { ContractForProjectHome } from '../home/ProjectHome'
import { Contract } from './Contract'
import { Fees } from './Fees'
import { FormsWithLoader as Forms } from './FormsWithLoader'
import { Integrations } from './Integrations'
import { ProjectInfo } from './ProjectInfo'
import { Reminders } from './Reminders'
import { Retention } from './Retention'
import { TaxesSettings } from './TaxesSettings'
import { Users } from './Users'

export enum BillingSettingsSection {
  PROJECT_INFO = 'PROJECT_INFO',
  CONTRACT = 'CONTRACT',
  RETENTION = 'RETENTION',
  INTEGRATIONS = 'INTEGRATIONS',
  REMINDERS = 'REMINDERS',
  FEES = 'FEES',
  TAXES = 'TAXES',
  USERS = 'USERS',
  FORMS = 'FORMS',
}

const DEFAULT_SECTION = BillingSettingsSection.PROJECT_INFO

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}))

const i18nBase = 'projects.subcontractors.settings'

interface BillingSettingsProps {
  contract: ContractForProjectHome
}

/** Displays a list of a project's settings and allows you to edit them. */
export function BillingSettings({ contract }: BillingSettingsProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const initialSelectedSection = useSearch({
    from: '/_a/billing/$projectId/_p/$tab',
    select: (search) => {
      if (
        Object.values(BillingSettingsSection).includes(search.section as BillingSettingsSection)
      ) {
        return search.section as BillingSettingsSection
      }
      return DEFAULT_SECTION
    },
  })

  const sectionToString = useCallback((section: BillingSettingsSection) => {
    return section
  }, [])

  const [selectedSection, setSelectedSection] = useState(initialSelectedSection)

  const [handleScrollToSection, handleScrollSectionIntoView, isWaypointDisabled, disableWaypoint] =
    useWaypoint<BillingSettingsSection>({
      setSelectedSection,
      sectionToString,
    })

  // If the user navigated to /billing/settings#section, scroll to that section on mount
  useEffect(() => {
    if (initialSelectedSection !== DEFAULT_SECTION) {
      handleScrollToSection(initialSelectedSection)
    }
  }, [handleScrollToSection, initialSelectedSection])

  const shouldShowRateTableFeesAndTaxes = !!contract.rateTable
  const isTimeAndMaterialsWithRateTable =
    contract.billingType === BillingType.TIME_AND_MATERIALS && !!contract.rateTable

  const sidebarTabs = useMemo(
    () =>
      Object.values(BillingSettingsSection)
        .filter((value) => {
          if (value === BillingSettingsSection.FEES && !shouldShowRateTableFeesAndTaxes) {
            return false
          }
          if (
            value === BillingSettingsSection.TAXES &&
            contract.billingType === BillingType.TIME_AND_MATERIALS
          ) {
            return false
          }
          return true
        })
        .map((value) => {
          let label = t(`${i18nBase}.tabs.${value}`)
          if (value === BillingSettingsSection.FEES && !isTimeAndMaterialsWithRateTable) {
            label = t(`${i18nBase}.tabs.COR_FEES`)
          }
          // If there are two tax sections, update the "taxes" label to specify "sov taxes"
          if (value === BillingSettingsSection.TAXES && shouldShowRateTableFeesAndTaxes) {
            label = t(`${i18nBase}.sov_taxes`)
          }
          return {
            key: value,
            label,
            value,
          }
        }),
    [contract.billingType, isTimeAndMaterialsWithRateTable, shouldShowRateTableFeesAndTaxes, t]
  )

  const sections = useMemo(() => {
    const settingsSections = [
      {
        section: BillingSettingsSection.PROJECT_INFO,
        content: <ProjectInfo contract={contract} />,
      },
      {
        section: BillingSettingsSection.CONTRACT,
        content: <Contract contract={contract} />,
      },
      {
        section: BillingSettingsSection.RETENTION,
        content: <Retention contract={contract} />,
      },
      {
        section: BillingSettingsSection.INTEGRATIONS,
        content: <Integrations contract={contract} />,
      },
      {
        section: BillingSettingsSection.REMINDERS,
        content: <Reminders contract={contract} />,
      },
    ]

    if (shouldShowRateTableFeesAndTaxes) {
      settingsSections.push({
        section: BillingSettingsSection.FEES,
        content: <Fees contract={contract} rateTable={contract.rateTable} />,
      })
    }

    if (contract.billingType !== BillingType.TIME_AND_MATERIALS) {
      settingsSections.push({
        section: BillingSettingsSection.TAXES,
        content: <TaxesSettings contract={contract} />,
      })
    }

    settingsSections.push({
      section: BillingSettingsSection.USERS,
      content: <Users contract={contract} />,
    })

    settingsSections.push({
      section: BillingSettingsSection.FORMS,
      content: <Forms contract={contract} />,
    })

    return settingsSections
  }, [contract, shouldShowRateTableFeesAndTaxes])

  return (
    <ContainerWithSideTabs
      className={classes.root}
      sidebarTabs={sidebarTabs}
      selectedSection={selectedSection}
      onSelectedSectionChange={setSelectedSection}
      handleScrollToSection={handleScrollToSection}
      handleScrollSectionIntoView={handleScrollSectionIntoView}
      isWaypointDisabled={isWaypointDisabled}
      disableWaypoint={disableWaypoint}
      sections={sections}
    />
  )
}
