import { gql } from '@apollo/client'
import { TFunction } from 'i18next'
import _ from 'lodash'
import pRetry from 'p-retry'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IntegrationTypeFamily,
  WriteSyncOperationStatus,
  getIntegrationTypeFamily,
  integrationTypes,
  supportsReadSync,
} from 'siteline-common-all'
import { evictWithGc } from 'siteline-common-web'
import AcumaticaIcon from '../../assets/icons/acumatica.png'
import FoundationIcon from '../../assets/icons/foundation.png'
import GcPayLogo from '../../assets/icons/gcpay.png'
import OracleIcon from '../../assets/icons/oracle.png'
import ProcoreIcon from '../../assets/icons/procore.svg'
import SageIntacctIcon from '../../assets/icons/sage-intacct.svg'
import Sage100Icon from '../../assets/icons/sage.png'
import Sage300Icon from '../../assets/icons/sage_300_cre.png'
import SpectrumIcon from '../../assets/icons/spectrum.png'
import VistaIcon from '../../assets/icons/vista.png'
import { ContractForBulkExport } from '../../components/billing/invoice/export/PayAppBulkExportDialog'
import { ContractForProjectContext } from '../contexts/ProjectContext'
import {
  CompanyProperties,
  ImportProjectOnboardingMetadataProperties,
  IntegrationType,
  LienWaiverProperties,
  MinimalIntegrationProperties,
  ReadIntegrationProjectQuery,
  WriteSyncOperationQuery,
  useCreateWriteSyncOperationMutation,
  useIntegrationProjectsLazyQuery,
  useReadIntegrationProjectLazyQuery,
  useWriteSyncOperationLazyQuery,
} from '../graphql/apollo-operations'
import { getProjectFromCache } from './Project'
import { getGeneralContractorCustomerIdFromComputerEaseMapping } from './export/ComputerEase'

gql`
  mutation createWriteSyncOperation($input: CreateWriteSyncOperationInput!) {
    createWriteSyncOperation(input: $input) {
      id
      status
    }
  }
`

gql`
  query writeSyncOperation($id: ID!) {
    writeSyncOperation(id: $id) {
      id
      status
      result
      payApp {
        id
        retentionOnly
        status
        payAppNumber
        billingType
      }
      legalRequirement {
        id
        name
      }
      legalDocuments {
        id
      }
      lienWaivers {
        id
        vendorContract {
          id
          vendor {
            id
            name
          }
        }
      }
      integration {
        id
        companyIntegration {
          id
          metadata
        }
      }
    }
  }
`

export type PolledWriteSyncOperation = WriteSyncOperationQuery['writeSyncOperation']

// Integration types that customers can actually see.
// This excludes our test integration.
export const PUBLIC_INTEGRATION_TYPES = _.values(IntegrationType).filter(
  (type) => type !== IntegrationType.TEST
)

export function getIntegrationIcon(type: IntegrationType): string | null {
  switch (type) {
    case IntegrationType.ACUMATICA:
      return AcumaticaIcon
    case IntegrationType.GC_PAY:
      return GcPayLogo
    case IntegrationType.TEXTURA:
      return OracleIcon
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
      return Sage100Icon
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
      return Sage300Icon
    case IntegrationType.SPECTRUM:
      return SpectrumIcon
    case IntegrationType.VISTA:
      return VistaIcon
    case IntegrationType.PROCORE:
      return ProcoreIcon
    case IntegrationType.FOUNDATION:
      return FoundationIcon
    case IntegrationType.SAGE_INTACCT:
      return SageIntacctIcon
    case IntegrationType.TEST:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.COMPUTER_EASE_FILE:
      return null
  }
}

/**
 * Returns the estimated time in a human readable form based on the integration type and number
 * of projects. For example, if it takes 30 seconds to import a single project and we have 10 to
 * import, then we will return the string "5 minutes".
 */
export function getEstimatedBulkImportTime(
  type: IntegrationType | null,
  numProjects: number,
  t: TFunction
): string {
  let secondsPerProject = 0
  switch (type) {
    case IntegrationType.ACUMATICA:
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.VISTA:
    case IntegrationType.SPECTRUM:
    case IntegrationType.PROCORE:
    case IntegrationType.FOUNDATION:
    case IntegrationType.SAGE_INTACCT:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
      secondsPerProject = 10
      break
    case IntegrationType.TEXTURA:
      secondsPerProject = 30
      break
    case IntegrationType.GC_PAY:
      secondsPerProject = 45
      break
    case IntegrationType.TEST:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.COMPUTER_EASE_FILE:
    case null:
      break
  }

  const totalSeconds = numProjects * secondsPerProject
  // Bucket to 5, 10, 20, 30 minute blocks and then "hour" afterwards
  if (totalSeconds < 300) {
    return t('projects.new_project.bulk_import_times.minutes', { amount: 5 })
  } else if (totalSeconds < 600) {
    return t('projects.new_project.bulk_import_times.minutes', { amount: 10 })
  } else if (totalSeconds < 1200) {
    return t('projects.new_project.bulk_import_times.minutes', { amount: 20 })
  } else if (totalSeconds < 1800) {
    return t('projects.new_project.bulk_import_times.minutes', { amount: 30 })
  } else {
    return t('projects.new_project.bulk_import_times.hours')
  }
}

/**
 * Gets all lien waivers that can be synced to a vendor on the given integration.
 */
export function getLienWaiversForSync(
  integration: MinimalIntegrationProperties,
  lienWaivers: LienWaiverProperties[]
): LienWaiverProperties[] {
  switch (integration.type) {
    case IntegrationType.TEXTURA: {
      const mappings = integration.mappings as integrationTypes.IntegrationMappingsTextura
      return lienWaivers.filter((lienWaiver) =>
        mappings.vendorContracts.some(
          (contract) => contract.sitelineVendorId === lienWaiver.vendorContract?.vendor.id
        )
      )
    }
    case IntegrationType.ACUMATICA:
    case IntegrationType.TEST:
    case IntegrationType.GC_PAY:
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SPECTRUM:
    case IntegrationType.VISTA:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.FOUNDATION:
    case IntegrationType.PROCORE:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.SAGE_INTACCT:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
      return []
  }
}

type ContractWithIntegrations<T extends Pick<MinimalIntegrationProperties, 'type'>> = {
  integrations: readonly T[]
}

/**
 * Returns whether a contract has an integration of the specified type.
 */
export function hasIntegration<T extends Pick<MinimalIntegrationProperties, 'type'>>(
  contract: ContractWithIntegrations<T>,
  type: IntegrationType
): boolean {
  return contract.integrations.some((integration) => integration.type === type)
}

/**
 * Returns a single integration of the specified family, or null if none exists.
 * There is a core assumption that only one integration of a given family can exist on a contract.
 */
export function getIntegrationOfFamily<T extends Pick<MinimalIntegrationProperties, 'type'>>(
  contract: ContractWithIntegrations<T>,
  type: IntegrationTypeFamily
): T | null {
  const found = contract.integrations.find(
    (integration) => getIntegrationTypeFamily(integration.type) === type
  )
  return found ?? null
}

/** Checks whether or not an integration exists on this contract. */
export function hasIntegrationOfFamily<T extends Pick<MinimalIntegrationProperties, 'type'>>(
  contract: ContractWithIntegrations<T>,
  type: IntegrationTypeFamily
): boolean {
  return getIntegrationOfFamily(contract, type) !== null
}

/** Finds an active company integration of a given integration type */
export function findCompanyIntegrationOfType(
  company: CompanyProperties | null,
  type: IntegrationType
) {
  if (!company) {
    return undefined
  }
  return company.companyIntegrations.find(
    (companyIntegration) => companyIntegration.type === type && !companyIntegration.archivedAt
  )
}

/** Returns a lazy query for reading project info from a third-party integration service */
export function useReadIntegrationProject({
  onCompleted,
  onError,
}: {
  onCompleted: (data: ImportProjectOnboardingMetadataProperties) => void
  onError: () => void
}) {
  const [importProject, { loading }] = useReadIntegrationProjectLazyQuery({
    // Always fetch from the network, since something may have changed that should be re-synced
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data: ReadIntegrationProjectQuery | null) => {
      if (!data) {
        return
      }
      const { readIntegrationProject } = data
      onCompleted(readIntegrationProject)
    },
    onError,
  })
  return [importProject, loading] as const
}

/**
 * Returns a lazy query for reading projects from an integration service, as well as the latest list
 * of projects returned by the query and an error if one occurred
 */
export function useIntegrationProjectsForOnboarding() {
  const [fetchProjects, { data, loading, error }] = useIntegrationProjectsLazyQuery({
    // Always query the network, as the user may have added new projects that need to be fetched
    fetchPolicy: 'network-only',
    // Needed to update `loading` every time a refetch is executed
    notifyOnNetworkStatusChange: true,
  })
  const integrationProjects = useMemo(() => {
    if (!data?.integrationProjects) {
      return []
    }
    return _.orderBy(
      data.integrationProjects,
      [(project) => project.projectName, (project) => project.contractName],
      ['asc', 'asc']
    )
  }, [data])
  return [fetchProjects, integrationProjects, loading || !data, error] as const
}

export function getIntegrationForReadLineItems(
  contract: ContractForProjectContext
): MinimalIntegrationProperties | undefined {
  // If the TEST integration exists, default to that as the read integration
  const testIntegration = contract.integrations.find(
    (integration) => integration.type === IntegrationType.TEST
  )
  if (testIntegration) {
    return testIntegration
  }

  const hasGcIntegration = hasIntegrationOfFamily(contract, IntegrationTypeFamily.GC_PORTAL)
  return contract.integrations.find((integration) => {
    if (!supportsReadSync(integration.type, 'changeOrders')) {
      return false
    }
    const integrationTypeFamily = getIntegrationTypeFamily(integration.type)
    // If there's a GC portal integration, only show import options for the GC portal
    return !hasGcIntegration || integrationTypeFamily === IntegrationTypeFamily.GC_PORTAL
  })
}

/**
 * Returns the integration vendor ID, if one was set for this integration type.
 */
export function getIntegrationVendorId(
  mappings: integrationTypes.VendorIntegrationMappings,
  companyIntegrationId: string
): string | null {
  const integrationMapping = (mappings.integrations ?? []).find(
    (integration) => integration.companyIntegrationId === companyIntegrationId
  )
  if (!integrationMapping) {
    return null
  }
  return integrationMapping.integrationVendorId
}

type UseWriteSyncProps = {
  integration: MinimalIntegrationProperties
}

export type SucceededOperation = Omit<PolledWriteSyncOperation, 'result' | 'status'> & {
  status: WriteSyncOperationStatus.COMPLETED
  result: integrationTypes.WriteSyncResultSuccess
}

export type FailedOperation = Omit<PolledWriteSyncOperation, 'result' | 'status'> & {
  status: WriteSyncOperationStatus.COMPLETED
  result: integrationTypes.WriteSyncResultFailure
}

export type DeferredOperation = Omit<PolledWriteSyncOperation, 'result'> & {
  status: WriteSyncOperationStatus.DEFERRED
  result: integrationTypes.WriteSyncResultFailure
}

export type QueuedInHh2Operation = Omit<PolledWriteSyncOperation, 'result'> & {
  status: WriteSyncOperationStatus.QUEUED_IN_HH2
  result: integrationTypes.WriteSyncResultQueuedInHh2
}

export type UseWriteSyncStatus =
  // Operation has not been created yet
  | { type: 'notCreated' }

  // Operation is being created via the `createWriteSyncOperation` mutation
  | { type: 'creating' }

  // Operation has been created. We're now polling for the result.
  | { type: 'polling' }

  // Sync operation succeeded
  | { type: 'success'; operation: SucceededOperation }

  // Sync operation failed in the integrations service
  | { type: 'syncError'; operation: FailedOperation }

  // Sync operation has been deferred, meaning it will be processed at a later date.
  // This usually occurs when a draw is not open yet.
  | { type: 'deferred'; operation: DeferredOperation }

  // Invoice was exported in hh2 but is still in queue for processing.
  // This usually occurs when there are many other operations (like after a deep sync), or when
  // a TSObject error needs to be resolved.
  | { type: 'queuedInHh2'; operation: QueuedInHh2Operation }

  // Operation was still running when we reached the 1min timeout.
  // We should tell the user that an email will be sent later on.
  | { type: 'stillRunningAfterClientTimeout' }

  // This is not a sync error, but rather an error that occurred either when trying to create the sync
  // or when trying to poll for the sync result. Sync errors are in the `completed` state.
  | { type: 'internalError'; error: Error }

export type UseWriteSyncResult = {
  sync: (payload: integrationTypes.WriteSyncPayload) => void
  status: UseWriteSyncStatus
  reset: () => void
}

class StillRunningError extends Error {}

/**
 * Creates a new write sync operation and polls for the result.
 * State of the creation/polling is returned, alongside a `sync` function that can be called to
 * start the sync / sync again.
 */
export function useWriteSync({ integration }: UseWriteSyncProps): UseWriteSyncResult {
  const [createWriteSyncOperation] = useCreateWriteSyncOperationMutation()
  const [getWriteSyncOperation] = useWriteSyncOperationLazyQuery({ fetchPolicy: 'network-only' })
  const [status, setStatus] = useState<UseWriteSyncStatus>({ type: 'notCreated' })
  const { t } = useTranslation()

  // If the integration ID changes, reset the state of the operation
  useEffect(() => {
    setStatus({ type: 'notCreated' })
  }, [integration.id])

  const doSync = useCallback(
    async (payload: integrationTypes.WriteSyncPayload) => {
      // Set status to creating
      setStatus({ type: 'creating' })

      // Create the operation
      const createResult = await createWriteSyncOperation({
        variables: {
          input: {
            integrationId: integration.id,
            payload,
          },
        },
        update(cache) {
          evictWithGc(cache, (evict) => {
            evict({ id: cache.identify(integration.companyIntegration), fieldName: 'metadata' })

            // Evict payApp.lastSync
            switch (payload.type) {
              case 'payAppTextura':
              case 'payAppGcPay':
              case 'payAppProcore':
              case 'payAppSage100':
              case 'payAppFoundation':
              case 'payAppQuickbooks':
              case 'payAppFoundationFileGenie':
              case 'payAppFoundationFileFsi':
              case 'payAppComputerEase':
              case 'payAppLineItemsSage300':
              case 'payAppLineItemsSpectrum':
              case 'payAppLineItemsVista':
              case 'payAppLineItemsAcumatica':
              case 'payAppLineItemsSageIntacct':
              case 'payAppManual':
                evict({ id: `PayApp:${payload.payAppId}`, fieldName: 'lastSync' })
                break
              case 'lienWaivers':
              case 'legalRequirement':
                break
            }
          })
        },
      })

      if (!createResult.data) {
        const error = new Error(createResult.errors?.[0].message)
        setStatus({ type: 'internalError', error })
        return
      }

      setStatus({ type: 'polling' })
      const operationId = createResult.data.createWriteSyncOperation.id

      // Keep polling operation until it resolves
      const resolvedOperation = await pRetry(
        async () => {
          const getResult = await getWriteSyncOperation({
            variables: { id: operationId },
          })

          if (!getResult.data) {
            throw new Error(t('integrations.internal_error'))
          }

          const operation = getResult.data.writeSyncOperation
          const status = operation.status
          switch (status) {
            // If operation is still running, throw so that pRetry makes another attempt
            case WriteSyncOperationStatus.NOT_STARTED:
            case WriteSyncOperationStatus.RUNNING:
              throw new StillRunningError(t('integrations.internal_error'))
            case WriteSyncOperationStatus.CANCELED:
            case WriteSyncOperationStatus.COMPLETED:
            case WriteSyncOperationStatus.DEFERRED:
            case WriteSyncOperationStatus.QUEUED_IN_HH2:
              return operation
          }
        },

        // Retry 60 times every second
        { retries: 60, factor: 1 }
      )

      switch (resolvedOperation.status) {
        // Operation could technically be canceled, but this is highly unlikely.
        // The operation would need to be deferred first, then canceled by someone else, all within
        // the same minute.
        case WriteSyncOperationStatus.CANCELED:
          throw new Error(t('integrations.internal_error'))

        // Operation was completed. Result indicates whether it was successful or not.
        case WriteSyncOperationStatus.COMPLETED: {
          if (!resolvedOperation.result) {
            throw new Error(t('integrations.internal_error'))
          }
          switch (resolvedOperation.result.type) {
            case 'success':
              setStatus({ type: 'success', operation: resolvedOperation as SucceededOperation })
              break
            case 'failure':
              setStatus({ type: 'syncError', operation: resolvedOperation as FailedOperation })
              break
            case 'queuedInHh2':
              throw new Error(t('integrations.internal_error'))
          }
          break
        }

        // Operation can be deferred if a draw is not open yet
        case WriteSyncOperationStatus.DEFERRED: {
          setStatus({ type: 'deferred', operation: resolvedOperation as DeferredOperation })
          break
        }

        // Operation was completed. Result indicates whether it was successful or not.
        case WriteSyncOperationStatus.QUEUED_IN_HH2: {
          setStatus({ type: 'queuedInHh2', operation: resolvedOperation as QueuedInHh2Operation })
          break
        }

        // These statuses should never be part of a resolved operation
        case WriteSyncOperationStatus.RUNNING:
        case WriteSyncOperationStatus.NOT_STARTED:
          throw new Error(t('integrations.internal_error'))
      }
    },
    [
      createWriteSyncOperation,
      getWriteSyncOperation,
      integration.companyIntegration,
      integration.id,
      t,
    ]
  )

  const sync = useCallback(
    (payload: integrationTypes.WriteSyncPayload) => {
      doSync(payload).catch((error) => {
        if (error instanceof StillRunningError) {
          setStatus({ type: 'stillRunningAfterClientTimeout' })
        } else {
          setStatus({ type: 'internalError', error })
        }
      })
    },
    [doSync]
  )

  const reset = useCallback(() => {
    setStatus({ type: 'notCreated' })
  }, [])

  return { sync, status, reset }
}

/**
 * Returns whether a sync is in progress.
 */
export function isWriteSyncInProgress(status: UseWriteSyncStatus): boolean {
  switch (status.type) {
    case 'creating':
    case 'polling':
      return true
    case 'notCreated':
    case 'success':
    case 'syncError':
    case 'deferred':
    case 'internalError':
    case 'stillRunningAfterClientTimeout':
    case 'queuedInHh2':
      return false
  }
}

/**
 * Returns base metrics for sync action buttons.
 */
export function getSyncActionButtonMetrics(baseMetrics: {
  projectId: string
  integrationName: string
  buttonAction: string
  payload: integrationTypes.WriteSyncPayload
}) {
  const project = getProjectFromCache(baseMetrics.projectId)
  if (project) {
    return {
      ...baseMetrics,
      projectName: project.name,
    }
  }

  return baseMetrics
}

export type CustomerNumberIntegrationType =
  | IntegrationType.FOUNDATION_FILE
  | IntegrationType.COMPUTER_EASE_FILE

export function doesIntegrationTypeSupportCustomerNumber(
  integrationType: IntegrationType
): integrationType is CustomerNumberIntegrationType {
  return (
    integrationType === IntegrationType.FOUNDATION_FILE ||
    integrationType === IntegrationType.COMPUTER_EASE_FILE
  )
}

/**
 * Extracts the customer number/ID from the metadata for a given integration type from a list of
 * potential integrations
 */
export function getCustomerNumberFromIntegrations({
  integrations,
  integrationType,
  integrationMappings,
  contract,
}: {
  integrations: readonly MinimalIntegrationProperties[]
  integrationType: CustomerNumberIntegrationType
  integrationMappings: integrationTypes.CompanyIntegrationMappings
  contract: ContractForBulkExport | null
}): string | null {
  const integration = integrations.find((integration) => integration.type === integrationType)

  switch (integrationType) {
    case IntegrationType.FOUNDATION_FILE: {
      if (!integration) {
        return null
      }
      const metadata = integration.metadata as integrationTypes.FoundationIntegrationMetadata
      return metadata.customerNumber ?? null
    }
    case IntegrationType.COMPUTER_EASE_FILE: {
      if (integration) {
        const metadata = integration.metadata as integrationTypes.ComputerEaseContractMetadata
        return metadata.customerId ?? null
      }
      const generalContractor = contract?.project.generalContractor?.company
      if (generalContractor) {
        const mappingCustomerId = getGeneralContractorCustomerIdFromComputerEaseMapping(
          integrationMappings,
          generalContractor.id
        )
        if (mappingCustomerId) {
          return mappingCustomerId
        }
      }
      return null
    }
  }
}
