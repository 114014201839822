import _ from 'lodash'
import {
  BillingType,
  MAX_SOV_CODE_LENGTH,
  centsToDollars,
  dollarsToCents,
  formatCentsToDollars,
} from 'siteline-common-all'
import { SitelineText } from 'siteline-common-web'
import {
  SpreadsheetCell,
  SpreadsheetFooterRow,
  SpreadsheetRow,
  SpreadsheetRowType,
  makeContentCell,
  makeDataCell,
} from '../../../common/components/Spreadsheet/Spreadsheet.lib'
import { doesWorksheetExceedTotalValue } from '../../../common/util/BillingWorksheet'
import {
  EditingSovLineItem,
  getChangeSetLineItemUpdates,
  getContractPreSitelineRetentionAmount,
} from '../../../common/util/ManageSov'
import { isNewBilledInRange } from '../../../common/util/PayApp'
import {
  GetLineItemRowForOnboardingProps,
  GetTotalsRowProps,
  LINE_ITEM_NAME_CHARACTER_LIMIT,
  getLineItemNameRightContent,
  makeChangeOrderApprovalCell,
  makeChangeOrderToggleCell,
  makePreSitelineBillingCell,
  makePreSitelineRetentionAmountCell,
  makeRetentionPercentCell,
  makeStoredMaterialsCell,
} from './ManageSovRow'

/**
 * Returns a `SpreadsheetRow` component to be displayed in the sov onboarding
 * table to represent a single lump sum line item
 */
export function getLumpSumLineItemRow(
  lineItem: EditingSovLineItem,
  {
    includePreSitelineBillingColumn,
    includeRetentionPercentColumn,
    isRetentionPercentEditable,
    includePreSitelineRetentionColumns,
    includeTotalBillingColumns,
    includeTotalRetainageColumn,
    includeChangeOrderColumn,
    includeChangeOrderApprovalColumn,
    includeMaterialsInStorageColumn,
    isChangeOrderApprovalDateEditable,
    includeTaxGroupColumn,
    roundRetention,
    onDelete,
    onToggleIsChangeOrder,
    onNavigateToChangeOrder,
    onEditChangeOrderDate,
    onResetPreSitelineRetentionAmount,
    timeZone,
    isFirstInUngroupedBlock,
    showWarningIfEmpty,
    t,
    isEditable,
    isReorderable,
    isRowSelected,
    changeSetUpdate,
  }: GetLineItemRowForOnboardingProps
): SpreadsheetRow {
  const i18nBase = 'projects.subcontractors.sov'
  const isChangeOrder = lineItem.isChangeOrder ?? false
  const {
    code,
    isCodeUpdated,
    name,
    isNameUpdated,
    costCode,
    isCostCodeUpdated,
    originalTotalValue,
    isOriginalTotalValueUpdated,
    preSitelineRetentionAmount,
    isPreSitelineRetentionAmountUpdated,
    defaultRetentionPercent,
    preSitelineBilling,
    isPreSitelineBillingUpdated,
    previousMaterialsInStorage,
    isPreviousMaterialsInStorageUpdated,
    changeOrderApprovedAt,
    isChangeOrderApprovedAtUpdated,
    changeOrderEffectiveAt,
    taxGroupId,
    isTaxGroupUpdated,
  } = getChangeSetLineItemUpdates({
    lineItem,
    update: changeSetUpdate,
    timeZone,
    includePreSitelineBillingColumn,
    includeRetentionPercentColumn,
    includePreSitelineRetentionColumns,
    includeMaterialsInStorageColumn,
    includeTaxGroupColumn,
  })
  const cells: SpreadsheetCell[] = [
    // Number
    makeDataCell(code, {
      borderColor: showWarningIfEmpty && !lineItem.code ? 'error' : 'default',
      backgroundColor: isCodeUpdated ? 'green10' : undefined,
      bold: isCodeUpdated === true && isRowSelected === true,
    }),
    // Name
    makeDataCell(name, {
      rightContent: getLineItemNameRightContent({
        lineItemId: lineItem.id,
        onDelete,
        isEditable,
        numWorksheetLineItems: lineItem.worksheetLineItems.length,
      }),
      borderColor: showWarningIfEmpty && !lineItem.name ? 'error' : 'default',
      backgroundColor: isNameUpdated ? 'green10' : undefined,
      bold: isNameUpdated === true && isRowSelected === true,
      characterLimit: LINE_ITEM_NAME_CHARACTER_LIMIT,
    }),
    // Cost code
    makeDataCell(costCode ?? '', {
      backgroundColor: isCostCodeUpdated ? 'green10' : undefined,
      bold: isCostCodeUpdated === true && isRowSelected === true,
      characterLimit: MAX_SOV_CODE_LENGTH,
    }),
    // Scheduled value
    makeDataCell(centsToDollars(originalTotalValue), {
      validate: (value) => {
        if (!isNewBilledInRange(centsToDollars(lineItem.billedToDate), Number(value))) {
          return { type: 'error', message: t(`${i18nBase}.billed_to_date_too_high`) }
        }
        const { exceedsTotalValue: doesWorksheetExceedSovLineItemValue, sumOfWorksheetLineItems } =
          doesWorksheetExceedTotalValue({
            sovLineItem: lineItem,
            currentWorksheetLineItem: null,
            toWorksheetLineItemValue: null,
            toSovLineItemValue: dollarsToCents(Number(value)),
          })
        if (doesWorksheetExceedSovLineItemValue) {
          const formattedSumOfWorksheetLineItems = formatCentsToDollars(
            sumOfWorksheetLineItems,
            true
          )
          return {
            type: 'error',
            message: t('projects.subcontractors.sov.worksheet_total_exceeds_sov', {
              worksheetLineItemsTotalValue: formattedSumOfWorksheetLineItems,
            }),
          }
        }
        return null
      },
      backgroundColor: isOriginalTotalValueUpdated ? 'green10' : undefined,
      bold: isOriginalTotalValueUpdated === true && isRowSelected === true,
    }),
  ]

  // Pre-Siteline billing
  if (includePreSitelineBillingColumn) {
    cells.push(
      makePreSitelineBillingCell({
        lineItem,
        preSitelineBilling,
        isPreSitelineBillingUpdated,
        t,
        isRowSelected,
        isEditable,
        billingType: BillingType.LUMP_SUM,
      })
    )
  }

  // Stored materials (stored pre-siteline, only applicable if carryover type is manual)
  if (includeMaterialsInStorageColumn) {
    cells.push(
      makeStoredMaterialsCell({
        preSitelineBilling,
        previousMaterialsInStorage: previousMaterialsInStorage ?? null,
        isPreviousMaterialsInStorageUpdated,
        t,
        unitPrice: null,
      })
    )
  }

  // Retention % (default or latest)
  if (includeRetentionPercentColumn) {
    cells.push(
      makeRetentionPercentCell({
        defaultRetentionPercent,
        latestRetentionPercent: lineItem.latestRetentionPercent,
        isRetentionPercentEditable,
        t,
      })
    )
  }

  // Pre-siteline retention $
  if (includePreSitelineRetentionColumns) {
    cells.push(
      makePreSitelineRetentionAmountCell({
        lineItem,
        defaultRetentionPercent,
        preSitelineBilling,
        preSitelineRetentionAmount,
        isPreSitelineRetentionAmountUpdated,
        roundRetention,
        includeRetentionPercentColumn,
        includePreSitelineBillingColumn,
        includePreSitelineRetentionColumns,
        onResetPreSitelineRetentionAmount,
        t,
        isRowSelected,
        isEditable,
      })
    )
  }

  if (includeTaxGroupColumn) {
    cells.push(
      makeDataCell(taxGroupId ?? '', {
        backgroundColor: isTaxGroupUpdated ? 'green10' : undefined,
        bold: isTaxGroupUpdated === true && isRowSelected === true,
      })
    )
  }

  // Change order checkbox
  if (includeChangeOrderColumn) {
    cells.push(
      makeChangeOrderToggleCell({
        lineItem,
        isChangeOrder,
        includeChangeOrderApprovalColumn,
        onToggleIsChangeOrder,
        onNavigateToChangeOrder,
        t,
      })
    )
  }

  // Approval date. Show this cell if:
  // 1. There's a column for approval date
  // 2. There's no change order column, or this is a change order. If there is a change order column
  // and this is not a change order, the previous change order cell with span two columns.
  if (includeChangeOrderApprovalColumn && (!includeChangeOrderColumn || isChangeOrder)) {
    cells.push(
      makeChangeOrderApprovalCell({
        changeOrderApprovedAt,
        changeOrderEffectiveAt,
        onEditChangeOrderDate,
        isChangeOrderApprovedAtUpdated,
        isChangeOrderApprovalDateEditable,
      })
    )
  }

  if (includeTotalBillingColumns) {
    cells.push(
      ...[
        // Completed to date
        makeDataCell(centsToDollars(lineItem.billedToDate)),
        // Balance to finish
        makeDataCell(centsToDollars(lineItem.latestTotalValue - lineItem.billedToDate)),
      ]
    )
  }

  if (includeTotalRetainageColumn) {
    cells.push(makeDataCell(centsToDollars(lineItem.retentionToDate ?? 0)))
  }

  return {
    type: SpreadsheetRowType.DEFAULT,
    id: lineItem.id,
    isFirstInUngroupedBlock,
    cells,
    isNonEditableRow: !isEditable,
    allowDragging: isReorderable,
  }
}

/**
 * Returns the totals row for a lump sum SOV, with total amounts for each column
 * to be displayed as a footer row in the invoice table
 */
export function getLumpSumTotalsRow({
  sov,
  includePreSitelineBillingColumn,
  includeRetentionPercentColumn,
  includePreSitelineRetentionColumns,
  includeMaterialsInStorageColumn,
  includeTotalBillingColumns,
  includeTotalRetainageColumn,
  includeChangeOrderColumn,
  includeChangeOrderApprovalColumn,
  includeTaxGroupColumn,
  isFirstInUngroupedBlock,
  t,
}: GetTotalsRowProps): SpreadsheetFooterRow {
  const lineItems = sov.lineItems
  const totalValue = _.sumBy(lineItems, (lineItem) => centsToDollars(lineItem.latestTotalValue))
  const totalPreviousBilled = _.sumBy(lineItems, (lineItem) =>
    centsToDollars(lineItem.preSitelineBilling ?? 0)
  )
  const totalStoredMaterials = _.sumBy(lineItems, (lineItem) =>
    centsToDollars(lineItem.previousMaterialsInStorage ?? 0)
  )
  const numItems = lineItems.length
    ? t('projects.subcontractors.sov.num_items', { count: lineItems.length })
    : ''
  const preSitelineRetentionAmount = getContractPreSitelineRetentionAmount(sov)
  const totalPreSitelineRetention = centsToDollars(preSitelineRetentionAmount)

  const cells = [
    makeContentCell(
      <SitelineText variant="secondary" bold>
        {t('projects.subcontractors.sov.total')}
      </SitelineText>,
      []
    ),
    makeDataCell(numItems, { colSpan: 2 }),
    makeDataCell(totalValue),
    ...(includePreSitelineBillingColumn ? [makeDataCell(totalPreviousBilled)] : []),
    ...(includeMaterialsInStorageColumn ? [makeDataCell(totalStoredMaterials)] : []),
    ...(includeRetentionPercentColumn ? [makeContentCell(null, [])] : []),
    ...(includePreSitelineRetentionColumns ? [makeDataCell(totalPreSitelineRetention)] : []),
    ...(includeTaxGroupColumn ? [makeContentCell(null, [])] : []),
    // Change order & approval date
    ...(includeChangeOrderColumn ? [makeContentCell(null, [])] : []),
    ...(includeChangeOrderApprovalColumn ? [makeContentCell(null, [])] : []),
  ]

  if (includeTotalBillingColumns) {
    const totalBilledToDate = _.sumBy(lineItems, (lineItem) =>
      centsToDollars(lineItem.billedToDate)
    )

    // Billed to date
    cells.push(makeDataCell(totalBilledToDate))
    cells.push(makeDataCell(totalValue - totalBilledToDate))
  }

  if (includeTotalRetainageColumn) {
    const totalRetentionToDate = centsToDollars(sov.totalRetentionHeld)
    cells.push(makeDataCell(totalRetentionToDate))
  }

  return {
    type: SpreadsheetRowType.FOOTER,
    id: 'totals',
    cells,
    isNonEditableRow: true,
    isFixed: true,
    isFirstInUngroupedBlock,
  }
}
