import DragHandleIcon from '@mui/icons-material/DragHandle'
import { Tooltip } from '@mui/material'
import { clsx } from 'clsx'
import _ from 'lodash'
import { Dispatch, MouseEvent, memo, useEffect, useRef } from 'react'
import type { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { isDesktop } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useIsVisible } from 'react-is-visible'
import { alignToJustifyContent, cellBackgroundColor } from '../SitelineTable.lib'
import {
  SpreadsheetColumn,
  SpreadsheetDataType,
  SpreadsheetRow,
  SpreadsheetValue,
  columnIndexForRowCell,
  getSpreadsheetCellId,
  isPreviousCellEditable,
} from './Spreadsheet.lib'
import { SpreadsheetCell } from './SpreadsheetCell'
import { SpreadsheetAction } from './SpreadsheetReducer'
import { SpreadsheetSelectCell } from './SpreadsheetSelectCell'
import {
  CellWidthUpdate,
  SpreadsheetColumnSizing,
  calculateCellLayout,
} from './SpreadsheetSizingReducer'

export interface SpreadsheetRowProps {
  row: SpreadsheetRow
  rowIndex: number
  columns: SpreadsheetColumn[]
  dispatch: Dispatch<SpreadsheetAction>
  columnSizing: SpreadsheetColumnSizing
  dragHandleProps?: DraggableProvidedDragHandleProps | null

  /** If true, will show drag handles for rearranging rows */
  includeDragHandles?: boolean

  /** If true, will disable drag handles if they're shown */
  disableReordering?: boolean

  /** Only provided if the selected cell is in this row */
  selectedCellIndex?: number

  /** Only provided if the editing cell is in this row */
  editingCellIndex?: number
  editingCellInitialValue?: string

  onCellWidthChange: (cellWidth: CellWidthUpdate) => void

  /**
   * If provided, this function will be called when the user attempts
   * to edit a cell. Instead of focusing the cell input, this function is called
   * and no edit will occur.
   */
  onBeforeEdit?: () => void

  /**
   * If provided, this function will be called when the user attempts
   * to save a cell. saving will only occur if this returns true.
   */
  onBeforeSave?: (params: {
    onSave: () => void
    onCancel: () => void
    rowId: string
    columnId: string
    fromValue: SpreadsheetValue
    toValue: SpreadsheetValue
  }) => void

  /**
   * Attach a right click event to the spreadsheet row. This will open a menu with
   * options to insert a row above or below.
   **/
  onContextMenu?: (event: MouseEvent) => void

  isSkeletonRow?: boolean
}

/** A row in a spreadsheet */
const SpreadsheetRowComponent = memo(function SpreadsheetRowComponent({
  row,
  rowIndex,
  columns,
  dispatch,
  columnSizing,
  onCellWidthChange,
  dragHandleProps,
  includeDragHandles,
  disableReordering,
  selectedCellIndex,
  editingCellIndex,
  editingCellInitialValue,
  onBeforeEdit,
  onBeforeSave,
  onContextMenu,
  isSkeletonRow,
}: SpreadsheetRowProps) {
  const { t } = useTranslation()
  const rowClasses = {
    isGroupHeader: row.isGroupHeaderRow,
  }
  const rowRef = useRef<HTMLDivElement>(null)
  const draggingRef = useRef<boolean>(false)
  const isRowVisible = useIsVisible(rowRef)

  // If `onEndOfSpreadsheetVisibilityChange` callback is provided, call it when the end of the spreadsheet
  // becomes visible
  useEffect(() => {
    if (row.onVisibilityChange) {
      row.onVisibilityChange(isRowVisible)
    }
  }, [row, isRowVisible])

  // A transparent break between rows for delineating separate SOV groups
  const groupDivider = <div className={clsx('row', 'groupDivider')} />

  // Group header rows with no cells should just render a flat divider
  if (row.isGroupHeaderRow && rowIndex > 0 && row.cells.length === 0) {
    return groupDivider
  }

  const isReorderingDisabled = disableReordering || row.isDragDisabled
  const tooltipTitle: string =
    row.isDragDisabled ?? t('common.spreadsheet.reordering_disabled_search')
  const showTooltip = isReorderingDisabled

  return (
    <div
      ref={rowRef}
      className={clsx('row', {
        isNonEditableRow: row.isNonEditableRow,
        clickable: row.isGroupHeaderRow && !!row.onClick,
      })}
      onClick={row.onClick}
    >
      {includeDragHandles && (
        <div
          className={clsx('cell', 'dragHandleColumn', {
            ...rowClasses,
            disableReordering: isReorderingDisabled,
          })}
          {...dragHandleProps}
          // An interal `event.preventDefault()` swallows the blur event when going directly
          // from editing a cell input to dragging, so we explicitly focus this cell to pull
          // focus away from the input and trigger a save event
          // https://github.com/atlassian/react-beautiful-dnd/issues/1872
          onMouseDown={(evt) => evt.currentTarget.focus()}
        >
          {row.allowDragging && (
            <>
              {showTooltip && (
                <Tooltip title={tooltipTitle} placement="top-start">
                  <DragHandleIcon />
                </Tooltip>
              )}
              {!showTooltip && <DragHandleIcon />}
            </>
          )}
        </div>
      )}
      {row.cells.map((cell, cellIndex) => {
        const handleBeforeEdit =
          cell.type === 'DATA' ? (cell.onBeforeEdit ?? onBeforeEdit) : onBeforeEdit
        const cellPosition = { rowIndex, cellIndex }
        const columnIndex = columnIndexForRowCell(cellPosition, row)
        const column = columnIndex < columns.length ? columns[columnIndex] : null
        if (!column) {
          return null
        }
        const dataType =
          cell.type === 'DATA'
            ? (cell.dataTypeOverride?.dataType ?? column.dataType)
            : column.dataType
        let fixedDecimals =
          column.dataType === SpreadsheetDataType.NUMBER ? column.fixedDecimals : undefined
        const maxDecimals = 'maxDecimals' in column ? column.maxDecimals : undefined
        if (
          cell.type === 'DATA' &&
          cell.dataTypeOverride?.dataType === SpreadsheetDataType.PERCENT
        ) {
          fixedDecimals = cell.dataTypeOverride.fixedDecimals
        }
        let timeZone: string = column.dataType === SpreadsheetDataType.DATE ? column.timeZone : ''
        if (cell.type === 'DATA' && cell.dataTypeOverride?.dataType === SpreadsheetDataType.DATE) {
          timeZone = cell.dataTypeOverride.timeZone
        }

        const isColumnEditable = !row.isNonEditableRow && column.isEditable
        const isEditableDataCell =
          isColumnEditable && cell.type === 'DATA' && (cell.isEditable ?? true)
        const isEditableContentCell =
          isColumnEditable && cell.type === 'CONTENT' && (cell.isEditable ?? false)
        const isEditableCell = isEditableDataCell || isEditableContentCell
        const previousCellIsEditable = isPreviousCellEditable(cellIndex, row, rowIndex, columns)
        const { width, offset } = calculateCellLayout(columnIndex, columnSizing, {
          colSpan: cell.colSpan,
          colOffset: cell.colOffset,
        })
        const widthStyle = {
          width,
          minWidth: width,
          maxWidth: width,
        }
        const borderStyles = {
          ...(cell.cellBorderOverrides?.top && { borderTop: cell.cellBorderOverrides.top }),
          ...(cell.cellBorderOverrides?.right && { borderRight: cell.cellBorderOverrides.right }),
          ...(cell.cellBorderOverrides?.bottom && {
            borderBottom: cell.cellBorderOverrides.bottom,
          }),
          ...(cell.cellBorderOverrides?.left && { borderLeft: cell.cellBorderOverrides.left }),
        }
        const isTouchDevice = !isDesktop
        const isSelected = _.isEqual(selectedCellIndex, cellIndex)
        const isEditing = _.isEqual(editingCellIndex, cellIndex)
        // Attach handleClick to iPad touch events, unless it was a drag event
        const handleTouchStart = () => {
          draggingRef.current = false
        }
        const handleTouchMove = () => {
          draggingRef.current = true
        }
        const handleTouchCancel = () => {
          draggingRef.current = true
        }
        const handleClick = () => {
          if (isEditing) {
            return
          }
          // If onBeforeEdit is provided, invoke onBeforeEdit instead of dispatching edit action
          // Note that onBeforeEdit passed directly to the cell is prioritized over onBeforeEdit passed to the row
          if ((isSelected || isTouchDevice) && handleBeforeEdit) {
            handleBeforeEdit()
            // If already selected, start editing.
          } else if (isSelected) {
            // SELECT cells handle clicks on their own to open their menu, so we don't need this
            // dispatch. Including it will cause issues closing the menu, since the click event
            // that closes the menu may trigger this function.
            if (dataType !== SpreadsheetDataType.SELECT) {
              dispatch({ type: 'EDIT_CELL' })
            }
            // If the user is on a touch device, bypass selection step
          } else if (isTouchDevice) {
            dispatch({ type: 'SELECT_AND_EDIT_CELL', cell: cellPosition })
            // Otherwise, select the cell.
          } else {
            dispatch({ type: 'SELECT_CELL', cell: cellPosition })
          }
        }

        const handleRightClick = (event: MouseEvent) => {
          if (onContextMenu && !row.isNonEditableRow) {
            event.preventDefault()
            event.stopPropagation()
            dispatch({ type: 'SELECT_CELL', cell: { rowIndex, cellIndex } })
            onContextMenu(event)
          }
        }
        return (
          <div
            key={getSpreadsheetCellId(row.id, column.id)}
            id={getSpreadsheetCellId(row.id, column.id)}
            className={clsx('cell', cell.type, {
              isEditable: isEditableCell,
              isPreviousCellEditable: previousCellIsEditable,
              isPreviousCellNotSelected: !_.isEqual(selectedCellIndex, cellIndex - 1),
              isSelected,
              isEditing,
              blueBackground: row.backgroundColor === 'blue',
              whiteBackground: row.backgroundColor === 'white',
              darkGreyBackground: row.backgroundColor === 'darkGrey',
              noBackground: cell.backgroundColor === 'none',
              wordBreakAll: column.wordBreak === 'break-all',
              redBorder: cell.borderColor === 'error',
              ...rowClasses,
            })}
            style={{
              ...widthStyle,
              ...borderStyles,
              backgroundColor: cellBackgroundColor(cell.backgroundColor),
            }}
            onContextMenu={handleRightClick}
            onDoubleClick={
              isEditableDataCell && !isEditing
                ? () => {
                    if (handleBeforeEdit) {
                      handleBeforeEdit()
                      return
                    }
                    dispatch({ type: 'SELECT_AND_EDIT_CELL', cell: cellPosition })
                  }
                : undefined
            }
            onClick={isEditableDataCell ? handleClick : undefined}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchCancel={handleTouchCancel}
            onTouchEnd={
              isEditableDataCell && isTouchDevice && draggingRef.current === false
                ? handleClick
                : undefined
            }
          >
            <div
              className={clsx('cellInner', {
                // Remove default padding for columns with no padding, or when editing a date since
                // the date input has its own padding. Ignore for skeleton rows, since the loading
                // state content is separate.
                noPadding:
                  !isSkeletonRow &&
                  (column.padding === 'none' ||
                    (dataType === SpreadsheetDataType.DATE && isEditing) ||
                    (dataType === SpreadsheetDataType.SELECT && isEditableCell)),
              })}
              style={{
                justifyContent: alignToJustifyContent(column.align),
                marginLeft: offset,
              }}
            >
              {cell.type === 'CONTENT' && cell.content}
              {cell.type === 'DATA' && dataType !== SpreadsheetDataType.SELECT && (
                <SpreadsheetCell
                  rowId={row.id}
                  value={cell.value}
                  columnId={column.id}
                  className={cell.className}
                  onCellWidthChange={onCellWidthChange}
                  dispatch={dispatch}
                  isEditing={isEditing}
                  validate={cell.validate}
                  onBeforeSave={onBeforeSave}
                  isGroupHeaderRow={row.isGroupHeaderRow}
                  initialValue={isEditing ? editingCellInitialValue : undefined}
                  characterLimit={cell.characterLimit}
                  bold={cell.bold}
                  strikethrough={cell.strikethrough}
                  italic={cell.italic}
                  color={cell.color}
                  fixedDecimals={fixedDecimals}
                  maxDecimals={maxDecimals}
                  leftContent={cell.leftContent}
                  tooltipTitle={cell.tooltipTitle}
                  rightContent={cell.rightContent}
                  textAlign={column.align}
                  isGrowColumn={column.grow === true}
                  dataType={dataType}
                  timeZone={timeZone}
                />
              )}
              {cell.type === 'DATA' && column.dataType === SpreadsheetDataType.SELECT && (
                <SpreadsheetSelectCell
                  rowId={row.id}
                  value={cell.value}
                  columnId={column.id}
                  className={cell.className}
                  onCellWidthChange={onCellWidthChange}
                  dispatch={dispatch}
                  isEditing={isEditing}
                  isSelected={isSelected}
                  validate={cell.validate}
                  onBeforeSave={onBeforeSave}
                  isGroupHeaderRow={row.isGroupHeaderRow}
                  initialValue={isEditing ? editingCellInitialValue : undefined}
                  bold={cell.bold}
                  strikethrough={cell.strikethrough}
                  italic={cell.italic}
                  color={cell.color}
                  tooltipTitle={cell.tooltipTitle}
                  isGrowColumn={column.grow === true}
                  isEditable={isEditableCell}
                  options={column.options}
                  searchPlaceholder={column.searchPlaceholder}
                  addOption={column.addOption}
                  allowEmpty={column.allowEmpty}
                />
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
})

export default SpreadsheetRowComponent
