import LaunchIcon from '@mui/icons-material/Launch'
import LockIcon from '@mui/icons-material/Lock'
import { Button } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { useNavigate } from '@tanstack/react-router'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Permission, SitelineText, colors, makeStylesFast } from 'siteline-common-web'
import { BlueChip } from '../../../common/components/SitelineChip'
import { SitelineTable } from '../../../common/components/SitelineTable'
import {
  GROW_TABLE_COLUMN,
  TableRow,
  makeFixedTableColumn,
  makeTableCell,
  makeTableHeader,
} from '../../../common/components/SitelineTable.lib'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import { useUserContext } from '../../../common/contexts/UserContext'
import {
  ContractUserRole,
  UserStatus,
  useCompanyUsersQuery,
} from '../../../common/graphql/apollo-operations'
import { filterOutSitelineEmails } from '../../../common/util/User'
import { ContractForProjectHome } from '../home/ProjectHome'
import { ManageUsersDialog } from './ManageUsersDialog'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(1),
    '& .header': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
      '& .viewSettings': {
        color: colors.grey50,
        marginRight: theme.spacing(2),
      },
    },
    '& .table .nameAndEmail .name': {
      // Create some spacing between name and email
      lineHeight: '24px',
    },
    '& .table .leadChip > *': {
      fontSize: theme.typography.caption.fontSize,
    },
  },
}))

const i18nBase = 'projects.subcontractors.settings.users'

interface UsersProps {
  contract?: ContractForProjectHome
}

/** Shows a project's users and allows you to add/remove them from the project. */
export function Users({ contract }: UsersProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { isContractActive } = useProjectContext()
  const currentUser = useUserContext()
  const { companyId, permissions } = useCompanyContext()
  const canEditCompanyUsers = permissions.includes(Permission.EDIT_COMPANY_USERS)
  const canEdit = permissions.includes(Permission.EDIT_PROJECT_SETTINGS) && isContractActive
  const { data } = useCompanyUsersQuery({ variables: { id: companyId } })

  const [manageDialogOpen, setManageDialogOpen] = useState(false)

  const filteredContractUsers = useMemo(() => {
    if (!data?.companyUsers) {
      return []
    }
    const allContractUsers = contract?.users ? contract.users : []
    const nonSitelineCompanyUsers = filterOutSitelineEmails(currentUser, [...data.companyUsers])
    const activeCompanyUsers = nonSitelineCompanyUsers.filter(
      (companyUser) => companyUser.status === UserStatus.ACTIVE
    )
    return allContractUsers.filter((contractUser) =>
      activeCompanyUsers.some((companyUser) => companyUser.user.id === contractUser.user.id)
    )
  }, [contract?.users, currentUser, data?.companyUsers])

  const content = useMemo(() => {
    const headers = [
      makeTableHeader({ key: 'name', label: t(`${i18nBase}.name`) }),
      makeTableHeader({ key: 'jobTitle', label: t(`${i18nBase}.job_title`) }),
      makeTableHeader({ key: 'actions', label: '', align: 'right' }),
    ]
    const columnWidths = [makeFixedTableColumn(300), GROW_TABLE_COLUMN, makeFixedTableColumn(100)]

    const rows: TableRow[] = _.chain(filteredContractUsers)
      .orderBy([
        // Always sort the current user first
        ({ user }) => user.id !== currentUser.id,
        ({ user }) => `${user.firstName} ${user.lastName}`,
      ])
      .map(({ user, role }) => {
        const cells = [
          <div key="name" className="nameAndEmail">
            <SitelineText variant="secondary" bold color="grey90" className="name">
              {user.firstName} {user.lastName}
            </SitelineText>
            <SitelineText variant="smallText" color="grey50" className="email">
              {user.email}
            </SitelineText>
          </div>,
          <SitelineText key="job" variant="secondary" color="grey50">
            {user.jobTitle}
          </SitelineText>,
          role === ContractUserRole.LEAD_PM ? (
            <BlueChip key="lead" label={t(`${i18nBase}.lead`)} size="small" className="leadChip" />
          ) : (
            <></>
          ),
        ]
        return {
          key: user.id,
          cells: cells.map((content) => makeTableCell(content)),
          showArrowOnHover: false,
        }
      })
      .value()
    return {
      headers,
      rows,
      columnWidths,
    }
  }, [t, filteredContractUsers, currentUser.id])

  const goToCompanyUsers = () => {
    navigate({ to: '/settings/company/users' })
  }

  return (
    <div className={classes.root}>
      <div className="header">
        <SitelineText variant="h3" bold color="grey90">
          {t(`${i18nBase}.title`)}
        </SitelineText>
        <div>
          {canEditCompanyUsers && (
            <Button
              variant="text"
              color="secondary"
              className="viewSettings"
              endIcon={<LaunchIcon fontSize="small" />}
              onClick={goToCompanyUsers}
            >
              {t(`${i18nBase}.user_permissions_and_settings`)}
            </Button>
          )}
          {!canEdit && (
            <SitelineText variant="body1" color="grey50" startIcon={<LockIcon fontSize="small" />}>
              {t('projects.subcontractors.settings.no_permission')}
            </SitelineText>
          )}
          {canEdit && (
            <Button variant="outlined" color="secondary" onClick={() => setManageDialogOpen(true)}>
              {t(`${i18nBase}.manage`)}
            </Button>
          )}
        </div>
      </div>
      <div className="table">
        <SitelineTable content={content} />
      </div>
      {contract && data?.companyUsers && (
        <ManageUsersDialog
          open={manageDialogOpen}
          onClose={() => setManageDialogOpen(false)}
          contract={contract}
          companyUsers={[...data.companyUsers]}
        />
      )}
    </div>
  )
}
