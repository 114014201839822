import { memo, useMemo } from 'react'
import { Z_INDEX } from '../themes/Main'
import {
  alignToAlignItems,
  alignToJustifyContent,
  getCellPaddingStyle,
  getColumnWidthStyle,
  TableCell as TableCellProps,
} from './SitelineTable.lib'

type SitelineTableCellProps = TableCellProps & {
  className: string
  innerClassName: string
  stickyLeftColumnOffset: number | null
  columnWidthStyle: ReturnType<typeof getColumnWidthStyle>
  rowAlign?: 'top' | 'center' | 'bottom'
  columnAlign?: 'left' | 'center' | 'right'
  columnCellPadding?: 'default' | 'vertical-only' | 'none'
}

/**
 * The table cell used by SitelineTable component. Note that all styles applied
 * through classNames are handled on the SitelineTable component. This component
 * is only responsible for memoizing the cell content.
 */
export const SitelineTableCell = memo(function SitelineTableCell({
  content,
  backgroundColor,
  stickyLeftColumnOffset,
  columnWidthStyle,
  className,
  innerClassName,
  rowAlign,
  columnAlign,
  columnCellPadding,
}: SitelineTableCellProps) {
  const cellStyles = useMemo(() => {
    const colorStyle = backgroundColor ? { backgroundColor } : {}
    const stickyLeftStyle =
      stickyLeftColumnOffset !== null
        ? {
            position: 'sticky' as const,
            left: stickyLeftColumnOffset,
            zIndex: Z_INDEX.stickyColumn,
          }
        : {}
    return {
      ...colorStyle,
      ...stickyLeftStyle,
      ...columnWidthStyle,
    }
  }, [backgroundColor, columnWidthStyle, stickyLeftColumnOffset])

  const innerCellStyles = useMemo(
    () => ({
      alignItems: alignToAlignItems(rowAlign),
      justifyContent: alignToJustifyContent(columnAlign),
      ...getCellPaddingStyle(columnCellPadding),
    }),
    [columnAlign, columnCellPadding, rowAlign]
  )

  return (
    <div className={className} style={cellStyles}>
      <div className={innerClassName} style={innerCellStyles}>
        {content}
      </div>
    </div>
  )
})
